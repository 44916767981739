const api = `/histories/navix-scribe`;
import { navixScribeV2Store } from "./NavixScribeV2Store";

const logResponse = (response) => {
  console.log("Response:", response);
};

// Fetch personas
export async function fetchPersonas() {
  const res = await store.api.get("/personas");
  return res.data;
}

// Fetch thread histories
export async function fetchThreadHistories() {
  const res = await store.api.get(`${api}/thread-histories`);
  return res.data;
}

// Fetch thread details
export async function fetchThreadDetails(threadId) {
  const res = await store.api.get(`/threads/${threadId}`);
  return res.data;
}

// Fetch messages for a thread
export async function fetchMessages(threadId) {
  const res = await store.api.get(`/threads/${threadId}/messages`);
  return res.data;
}

export async function fetchHistories(
  currentPage = 1,
  toolType,
  toolAction,
  activeButton
) {
  const res = await store.api.get(
    `${api}?page=${currentPage}&toolType=${toolType}&toolAction=${toolAction}&isArchived=${activeButton}`
  );
  return res.data;
}

// Update history
export async function updateHistory(id, payload) {
  return await store.api.put(`/histories/navix-scribe/${id}`, { ...payload });
}

//#region Input Tool API

// Transcribe audio
export async function transcribeAudio(file, audioDuration) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("audioDuration", audioDuration);

  const response = await store.api.post(
    "/ai/v2/audio/transcribe-audio-v2",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  logResponse(response);
  return response;
}

export async function waitForTranscription(
  transcriptId,
  timeout = 600000,
  interval = 5000
) {
  const endTime = Date.now() + timeout;

  const checkStatus = async () => {
    try {
      const response = await store.api.get(
        `/histories/navix-scribe/${transcriptId}`
      );
      const data = response.data;
      if (data.history.audioTranscriber.status === "done") {
        return data.history;
      } else if (data.history.audioTranscriber.status === "error") {
        throw new Error("Transcription failed.");
      } else if (Date.now() < endTime) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        return checkStatus();
      } else {
        throw new Error("Transcription timed out.");
      }
    } catch (error) {
      console.error("Error checking transcription status:", error);
      throw error;
    }
  };

  return checkStatus();
}

// Save text
export async function saveText(text, file = null) {
  const formData = new FormData();
  formData.append("text", text);

  // Only append the file if it exists and is valid
  if (file && file.type === "application/pdf") {
    formData.append("file", file);
  }

  try {
    const response = await store.api.post(
      "/ai/navixscribe/input/save-text",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    logResponse(response);
    return response;
  } catch (error) {
    console.error("Error in saveText:", error);
    throw error;
  }
}

// Retranscribe audio
export async function retranscribeAudio(transcriptId) {
  const response = await store.api.post(
    `/ai/audio/manual-retranscribe/${transcriptId}`
  );
  logResponse(response);
  return response;
}
//#endregion

// Check account status
export async function checkAccountStatus() {
  const response = await store.api.get("/user/status");
  logResponse(response);
  return response.data;
}
