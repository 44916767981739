import { inject, observer } from "mobx-react";
import { useState, useRef, useEffect, useCallback } from "react";
import Loader from "../../../Components/Loader";
import styled from "styled-components";
import { saveText, updateHistory } from "../api.service.v2";
import {
  createThread,
  addMessage,
  runAssistant,
  fetchMessages,
  addFileToThread,
} from "../azureApiService";
import {
  PaperClipIcon,
  ArrowRightIcon,
  ClipboardListIcon,
  BookOpenIcon,
  ChatAltIcon,
  AcademicCapIcon,
  AdjustmentsIcon,
  BeakerIcon,
} from "@heroicons/react/solid";
import ToolModal from "./ToolModal";
import AskQuestionModal from "./AskQuestionModal";
import formatGuidance from "./formatGuidance";
import {
  progressNoteInstructions,
  hpAssessmentInstructions,
} from "./medicalNoteInstructions";

const InteractionPanel = inject("navixScribeV2Store")(
  observer(({ navixScribeV2Store }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [isAskQuestionModalOpen, setIsAskQuestionModalOpen] = useState(false);
    const [isMedicalNoteModalOpen, setIsMedicalNoteModalOpen] = useState(false);
    const fileInputRef = useRef(null);
    const textAreaRef = useRef(null);
    const containerRef = useRef(null);

    const handleOnChangeEvent = (e) => {
      const { value } = e.target;
      setMessage(value);
    };

    const supportedExtensions = [
      "c",
      "cpp",
      "cs",
      "css",
      "doc",
      "docx",
      "html",
      "java",
      "js",
      "json",
      "md",
      "pdf",
      "php",
      "pptx",
      "py",
      "rb",
      "sh",
      "tex",
      "ts",
      "txt",
    ];

    const handleFileChangeEvent = (e) => {
      const file = e.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (supportedExtensions.includes(fileExtension)) {
          setUploadedFile(file);
          console.log("File uploaded:", file.name);
        } else {
          alert("Unsupported file type. Please upload a supported file.");
          e.target.value = null;
        }
      }
    };

    const handleTextSubmit = async () => {
      try {
        setLoading(true);
        const currentThreadId = navixScribeV2Store.selectedThread;

        if (currentThreadId) {
          if (message) {
            await addMessage(currentThreadId, "user", message);
          }

          if (uploadedFile) {
            await addFileToThread(currentThreadId, uploadedFile);
          }

          await runAssistant(
            currentThreadId,
            "asst_HagPjnY7H8EYT5gRm8o8DSsX",
            ""
          );
          const fetchedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(fetchedMessages);
        } else {
          const response = await saveText(message);

          if (response.data.history && response.data.history._id) {
            const history = response.data.history;
            let newThreadId = history.threadId;

            if (!newThreadId) {
              const newThread = await createThread();
              newThreadId = newThread.thread_id;

              await updateHistory(history._id, { threadId: newThreadId });
            }

            await addMessage(newThreadId, "user", message);

            if (uploadedFile) {
              await addFileToThread(newThreadId, uploadedFile);
            }

            await runAssistant(
              newThreadId,
              "asst_HagPjnY7H8EYT5gRm8o8DSsX",
              ""
            );

            const fetchedMessages = await fetchMessages(newThreadId);
            navixScribeV2Store.setMessages(fetchedMessages);

            navixScribeV2Store.setSelectedThread(newThreadId);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setMessage("");
        setUploadedFile(null);
        setLoading(false);
      }
    };

    const handleRunAssistant = async (assistantId, message, format = "") => {
      if (loading) return;
      try {
        setLoading(true);
        const currentThreadId = navixScribeV2Store.selectedThread;
        if (currentThreadId) {
          let instructions = "";
          if (format && formatGuidance[format]) {
            instructions = formatGuidance[format];
          }

          await addMessage(currentThreadId, "user", message);

          if (uploadedFile) {
            await addFileToThread(currentThreadId, uploadedFile);
          }

          await runAssistant(currentThreadId, assistantId, instructions);
          const updatedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(updatedMessages);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsModalOpen(false);
        setUploadedFile(null);
      }
    };

    const handleConfirmAndRunAssistant = async (assistantId, format) => {
      await handleRunAssistant(
        assistantId,
        `Generate a ${format} note.`,
        format
      );
      setIsModalOpen(false);
    };

    const handleAskQuestion = async (question) => {
      try {
        setLoading(true);
        const currentThreadId = navixScribeV2Store.selectedThread;

        if (currentThreadId) {
          await addMessage(currentThreadId, "user", question);
          await runAssistant(
            currentThreadId,
            "asst_f2zVx6gLotQaJwVlHPBYfkEX",
            ""
          );
          const updatedMessages = await fetchMessages(currentThreadId);
          navixScribeV2Store.setMessages(updatedMessages);
        } else {
          console.error("No current thread available");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
        setIsAskQuestionModalOpen(false);
      }
    };

    const isSubmitButtonDisabled = () => {
      return (!message && !uploadedFile) || loading;
    };

    const handleAttachClick = () => {
      fileInputRef.current.click();
    };

    useEffect(() => {
      if (textAreaRef.current && containerRef.current) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.maxHeight = "30px";
        containerRef.current.style.height = "50px";

        if (textAreaRef.current.scrollHeight > 30) {
          const newHeight = Math.min(textAreaRef.current.scrollHeight, 120);
          textAreaRef.current.style.maxHeight = "120px";
          textAreaRef.current.style.height = `${newHeight}px`;
          containerRef.current.style.height = `${newHeight + 20}px`;
        }
      }
    }, [message]);

    const buttons = [
      {
        label: "Clinical Note",
        icon: <ClipboardListIcon className="icon" />,
        assistantId: "asst_nEalig2Xy5NtcDWDqOzdHZJr",
        showModal: true,
        message: "Generate a clinical note.",
      },
      {
        label: "Medical Notes",
        icon: <BookOpenIcon className="icon" />,
        assistantId: "asst_jHdxpnBQ8YyJYiDCjG2mcjxk",
        showModal: true,
        message: "Generate medical notes.",
      },
      {
        label: "Ask a Question",
        icon: <ChatAltIcon className="icon" />,
        assistantId: "asst_f2zVx6gLotQaJwVlHPBYfkEX",
        showModal: true,
        message: "Ask a question.",
      },
      {
        label: "Create Summary",
        icon: <AcademicCapIcon className="icon" />,
        assistantId: "asst_c8v8h2FGr4aQuLVrAn9wCxTo",
        showModal: false,
        message: "Create a summary.",
      },
      {
        label: "Coder",
        icon: <AdjustmentsIcon className="icon" />,
        assistantId: "asst_7OHxzqLKthcN04ErEMgVe3m1",
        showModal: false,
        message: "Run code analysis.",
      },
      {
        label: "Diagnostics",
        icon: <BeakerIcon className="icon" />,
        assistantId: "asst_YS3mhG6GZ7CLVJPbYATxmqrJ",
        showModal: false,
        message: "Run diagnostics.",
      },
    ];

    const handleKeyDown = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleTextSubmit();
      }
    };

    const handleDrop = useCallback(
      (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
          const fileExtension = file.name.split(".").pop().toLowerCase();
          if (supportedExtensions.includes(fileExtension)) {
            setUploadedFile(file);
            console.log("File uploaded:", file.name);
          } else {
            alert("Unsupported file type. Please upload a supported file.");
          }
        }
      },
      [supportedExtensions]
    );

    const handleDragOver = useCallback((e) => {
      e.preventDefault();
    }, []);

    useEffect(() => {
      const container = containerRef.current;
      if (container) {
        container.addEventListener("dragover", handleDragOver);
        container.addEventListener("drop", handleDrop);
        return () => {
          container.removeEventListener("dragover", handleDragOver);
          container.removeEventListener("drop", handleDrop);
        };
      }
    }, [handleDragOver, handleDrop]);

    const handleConfirmAndRunMedicalNote = async (noteType) => {
      let instructions = "";
      if (noteType === "Progress Note") {
        instructions = progressNoteInstructions;
      } else if (noteType === "H&P Assessment") {
        instructions = hpAssessmentInstructions;
      }

      const currentThreadId = navixScribeV2Store.selectedThread;
      if (currentThreadId) {
        await addMessage(currentThreadId, "user", `Generate a ${noteType}.`);
        await runAssistant(
          currentThreadId,
          "asst_jHdxpnBQ8YyJYiDCjG2mcjxk",
          instructions
        );
        const updatedMessages = await fetchMessages(currentThreadId);
        navixScribeV2Store.setMessages(updatedMessages);
      } else {
        console.error("No current thread available");
      }
      setIsMedicalNoteModalOpen(false);
    };

    return (
      <InteractionPanelContainer>
        <CenteredButtonGroup>
          {buttons.map((button, index) => (
            <ToolButton
              key={index}
              onClick={() => {
                if (button.label === "Ask a Question") {
                  setIsAskQuestionModalOpen(true);
                } else if (button.label === "Medical Notes") {
                  setIsMedicalNoteModalOpen(true);
                } else if (button.label === "Clinical Note") {
                  setIsModalOpen(true);
                } else if (button.showModal) {
                  setIsModalOpen(true);
                } else if (button.assistantId) {
                  handleRunAssistant(button.assistantId, button.message);
                }
              }}
            >
              {button.icon}
              <span>{button.label}</span>
            </ToolButton>
          ))}
        </CenteredButtonGroup>

        <TextInputContainer ref={containerRef}>
          <TextInput
            ref={textAreaRef}
            placeholder="Have a conversation with your Encounter"
            value={message}
            onChange={handleOnChangeEvent}
            onKeyDown={handleKeyDown}
          />
          {uploadedFile && (
            <UploadedFileLabel>{uploadedFile.name}</UploadedFileLabel>
          )}
          <AttachButton onClick={handleAttachClick}>
            <PaperClipIcon className="icon" />
          </AttachButton>
          <input
            type="file"
            onChange={handleFileChangeEvent}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <SubmitButton
            onClick={handleTextSubmit}
            disabled={isSubmitButtonDisabled()}
          >
            {loading ? (
              <Loader active={loading} className="w-6 h-6 mr-1" />
            ) : (
              <ArrowRightIcon className="icon" />
            )}
          </SubmitButton>
        </TextInputContainer>

        <ToolModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Create Clinical Note"
          options={["SOAP", "DAP", "SIRP", "BIRP", "GIRP", "GROUP"]}
          onGenerate={(format) =>
            handleConfirmAndRunAssistant(
              "asst_nEalig2Xy5NtcDWDqOzdHZJr",
              format
            )
          }
        />

        <ToolModal
          isOpen={isMedicalNoteModalOpen}
          onClose={() => setIsMedicalNoteModalOpen(false)}
          title="Create Medical Note"
          options={["Progress Note", "H&P Assessment"]}
          onGenerate={handleConfirmAndRunMedicalNote}
        />

        <AskQuestionModal
          isOpen={isAskQuestionModalOpen}
          onClose={() => setIsAskQuestionModalOpen(false)}
          onConfirm={handleAskQuestion}
        />
      </InteractionPanelContainer>
    );
  })
);

const InteractionPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

const CenteredButtonGroup = styled.div`
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 20px; /* Space between buttons */
  width: 100%;
  margin-bottom: 10px;
`;

const ToolButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  border: 1px solid #5c4bdb; /* Violet border */
  border-radius: 16px;
  background: #fff;
  transition: all 0.3s ease;
  width: 172px; /* Adjusted to match the Figma dimensions */
  height: 113px; /* Adjusted to match the Figma dimensions */
  position: relative; /* Ensures absolute positioning works */

  .icon {
    width: 24px;
    height: 24px;
    color: #4336ab;
    align-self: flex-start; /* Align the icon to the left */
    position: absolute;
    top: 23px; /* Adjusted to match the Figma spacing */
    left: 35px; /* Adjusted to match the Figma spacing */
  }

  span {
    color: #8b8b8e; /* Adjusted text color to match Figma */
    font-size: 16px; /* Base font size */
    font-family: "SF Pro Display", sans-serif; /* Matching Figma font */
    font-weight: 500; /* Medium weight */
    margin-top: 30px; /* Add margin to move the text down */
  }

  &:hover {
    background-color: #f3f4f6;
    border-color: #7c3aed;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const TextInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  gap: 0.5rem;
  position: relative;
  height: 50px;
  transition: height 0.3s ease;
`;

const TextInput = styled.textarea`
  flex: 1;
  border: none;
  background: transparent;
  padding: 7px 0 7px 15px;
  font-size: 1rem;
  outline: none;
  resize: none;
  transition: height 0.3s ease;
  overflow-y: auto;
`;

const SubmitButton = styled.button`
  background-color: #4c1d95;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;

  .icon {
    width: 20px;
    height: 20px;
    color: white;
  }

  &:hover {
    background-color: #c4b2ff;
  }

  &:disabled {
    background-color: #e0d7ff;
    cursor: not-allowed;
  }
`;

const UploadedFileLabel = styled.div`
  margin-top: 5px;
  padding: 2px 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 0.8em;
  color: #333;
`;

const AttachButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background-color: #f0f0f0; /* Adjust background color as needed */
  transition: background-color 0.3s;

  .icon {
    width: 24px;
    height: 24px;
    color: #888888; /* Adjust icon color as needed */
  }

  &:hover {
    background-color: #e0e0e0; /* Adjust hover background color as needed */
  }
`;

export default InteractionPanel;
