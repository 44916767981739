import React, { useState } from "react";
import styled from "styled-components";

const ToolModal = ({ isOpen, onClose, title, options, onGenerate }) => {
  const [selectedFormat, setSelectedFormat] = useState("");

  const handleConfirm = () => {
    if (selectedFormat) {
      onGenerate(selectedFormat);
      onClose();
    }
  };

  return (
    isOpen && (
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            {options.map((option) => (
              <OptionButton
                key={option}
                onClick={() => setSelectedFormat(option)}
                isSelected={selectedFormat === option}
              >
                {option}
              </OptionButton>
            ))}
          </ModalBody>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <ConfirmButton onClick={handleConfirm} disabled={!selectedFormat}>
            Confirm
          </ConfirmButton>
        </ModalContent>
      </ModalOverlay>
    )
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
`;

const ModalHeader = styled.h2`
  margin-bottom: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const OptionButton = styled.button`
  background-color: ${(props) => (props.isSelected ? "#d1c4e9" : "#f0f0f0")};
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ConfirmButton = styled.button`
  background-color: #6200ea;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;

  &:hover {
    background-color: #3700b3;
  }

  &:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
  }
`;

export default ToolModal;
