import { inject, observer } from "mobx-react";
import config from "../../../config";
import { useEffect, useRef, useState } from "react";
import EntryAudio from "../../../Components/EntryAudio";
import WaveSurferRecorder from "../../../Components/WaveSurferRecorder";
import toast from "react-hot-toast";
import Loader from "../../../Components/Loader";
import styled from "styled-components";
import { UploadIcon, DownloadIcon } from "@heroicons/react/outline";
import {
  decodeTextFile,
  saveText,
  transcribeAudio,
  checkAccountStatus,
} from "../api.service";
import { v4 as uuid } from "uuid";
import {
  formatElapsedTime,
  formatDuration,
} from "../../../tools/audio/audioUtils";
import Modal from "../../../Components/Modal";

const TEXT_ACCEPT_TYPES = [
  "text/plain",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const AUDIO_ACCEPT_TYPES = [
  "audio/mp3",
  "audio/wav",
  "audio/aac",
  "audio/flac",
  "audio/ogg",
  "audio/x-ms-wma",
  "audio/x-m4a",
  "audio/aiff",
];

const Inputs = inject(
  "store",
  "navixScribeStore"
)(
  observer(({ store, navixScribeStore }) => {
    const [loading, setLoading] = useState(false);
    const [activeButton, setActiveButton] = useState("audio");
    const [inputAccept, setInputAccept] = useState(TEXT_ACCEPT_TYPES);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioDuration, setAudioDuration] = useState(0);
    const audioComponentRef = useRef(null);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
      if (activeButton === "text") {
        setInputAccept(TEXT_ACCEPT_TYPES);
      } else if (activeButton === "audio") {
        setInputAccept([
          "audio/mp3,audio/wav,audio/aac,audio/flac,audio/ogg,audio/x-ms-wma,audio/x-m4a,audio/aiff",
        ]);
      }
      isGenerateButtonDisabled();
    }, [activeButton]);

    useEffect(() => {
      if (navixScribeStore.inputActiveRecord.subToolAction === "audio") {
        if (navixScribeStore.inputActiveRecord.audioTranscriber) {
          setAudioUrl(
            navixScribeStore.inputActiveRecord.audioTranscriber?.aws_url
          );
        }
      }
    }, [navixScribeStore.inputActiveRecord]);

    const clearAudioChunks = () => {
      const current = audioComponentRef.current;

      if (current) {
        current.clearAudioChunks();
      }
    };

    const isGenerateButtonDisabled = () => {
      const {
        inputForm: { inputText, file },
      } = navixScribeStore;
      const isEmptyText = activeButton === "text" && !inputText;
      const isEmptyAudio = activeButton === "audio" && !file;

      return isEmptyText || isEmptyAudio || loading;
    };

    const handleOnChangeEvent = (e) => {
      const { value, name } = e.target;
      navixScribeStore.setInputForm(name, value);
    };

    const handleUpgradeClick = () => {
      const form = document.createElement("form");
      form.method = "POST";
      form.action = `${store.baseURL}user/stripe/subscribe`;

      const tokenInput = document.createElement("input");
      tokenInput.type = "hidden";
      tokenInput.name = "token";
      tokenInput.value = store.api.defaults.headers.common["x-access-token"];
      form.appendChild(tokenInput);

      const planInput = document.createElement("input");
      planInput.type = "hidden";
      planInput.name = "plan";
      planInput.value = "Pro";
      form.appendChild(planInput);

      const priceIdInput = document.createElement("input");
      priceIdInput.type = "hidden";
      priceIdInput.name = "priceId";
      priceIdInput.value = config.stripe.pro;
      form.appendChild(priceIdInput);

      document.body.appendChild(form);
      form.submit();
    };

    const handleSubmitEvent = async () => {
      const {
        inputForm: { inputText, file },
        setInputForm,
        setActiveHistory,
        setTriggerSection,
      } = navixScribeStore;

      if (activeButton === "audio") {
        clearAudioChunks();
      }

      try {
        const result = await checkAccountStatus();
        if (!result.success) {
          toast.error(result.message);
          setModalMessage(result.message);
          setShowUpgradeModal(true);
          return;
        }

        const commonPayload = {
          _id: uuid(),
          title: `${activeButton.toUpperCase()} ${
            navixScribeStore.inputUploads.length + 1
          }`,
          created: new Date(),
          toolType: "NavixScribe",
          toolAction: "Input",
          subToolAction: activeButton,
          isDeleted: false,
          isArchived: false,
          favorite: false,
          forgotten: false,
        };
        const processAudioForTranscription = (audioData) => {
          return new Promise((resolve, reject) => {
            const duration = Math.floor(
              navixScribeStore.savedDuration / 1000 - 1
            );
            const durationString = formatDuration(duration);
            transcribeAudio(file, duration, durationString)
              .then((result) => {
                setInputForm("audioDuration", duration);
                setInputForm("audioDurationString", durationString);
                toast.success(result.data.message, { duration: 5000 });

                if (result.data.history && result.data.history._id) {
                  setActiveHistory(result.data.history._id, "Input");
                  console.log(
                    "processAudioForTranscription - History set as active:",
                    result.data.history
                  );
                }
                const inputUploads = navixScribeStore.inputUploads.filter(
                  (item) => item._id !== commonPayload._id
                );
                navixScribeStore.setInputUploads(inputUploads);

                resolve();
              })
              .catch((err) => {
                toast.error("Something went wrong.");
                reject(err);
              })
              .finally(() => {
                setTriggerSection("Input");
              });
          });
        };

        if (activeButton === "audio") {
          navixScribeStore.setInputUploads([
            {
              ...commonPayload,
              audioTranscriber: {
                status: "processing",
              },
            },
            ...navixScribeStore.inputUploads,
          ]);
          navixScribeStore.setInputForm("inputText", "");

          if (file && file instanceof Blob) {
            file
              .arrayBuffer()
              .then((audioData) => processAudioForTranscription(audioData))
              .catch((err) => {
                toast.error("Something went wrong with audio processing.");
                console.error(err);
              });
          } else if (audioUrl) {
            fetch(audioUrl)
              .then((response) => response.arrayBuffer())
              .then((audioData) => processAudioForTranscription(audioData))
              .catch((err) => {
                toast.error("Something went wrong with fetching audio.");
                console.error(err);
              });
          } else {
            throw new Error("No valid audio source found");
          }
        } else {
          navixScribeStore.setInputUploads([
            {
              ...commonPayload,
              currentPrompt: "Input Transcription",
              skipCreditForOutput: true,
            },
            ...navixScribeStore.inputUploads,
          ]);

          saveText(inputText, file)
            .then((result) => {
              toast.success("Successfully saved", { duration: 5000 });

              console.log("handleSubmitEvent - Save Text Result:", result);
              if (result.data.history && result.data.history._id) {
                setActiveHistory(result.data.history._id, "Input");
                console.log(
                  "Before setInputActiveRecord:",
                  result.data.history
                );
                navixScribeStore.setInputActiveRecord(result.data.history);
                console.log(
                  "After setInputActiveRecord:",
                  navixScribeStore.inputActiveRecord
                );
              }

              const inputUploads = navixScribeStore.inputUploads.filter(
                (item) => item._id !== commonPayload._id
              );
              navixScribeStore.setInputUploads(inputUploads);
            })
            .catch((err) => {
              toast.error("Something went wrong.");
              console.error(err);
            })
            .finally(() => {
              setTriggerSection("Input");
            });
        }
      } catch (err) {
        toast.error("Error checking account status.");
        console.error(err);
      }
    };

    const handleFileChangeEvent = async (e) => {
      const file = e.target.files[0];
      const { setInputForm } = navixScribeStore;
      if (!file) return;

      try {
        setLoading(true);

        const MAX_FILE_SIZE_MB = 100;
        const sizeInMb = file.size / 1024 / 1024;
        if (sizeInMb > MAX_FILE_SIZE_MB) {
          toast.error("file size must be less than or equal to 50mb");
          return;
        }

        if (activeButton === "text") {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            const data = await decodeTextFile(file);
            setInputForm("inputText", data.text);
            console.log(
              "File processed and inputForm set with data:",
              data.text
            );
            navixScribeStore.setInputActiveRecord({
              ...navixScribeStore.inputActiveRecord,
              output: data.text,
            });
          };
        } else {
          setAudioUrl(audioUrl);
          setInputForm("file", file);
        }

        setInputForm("filename", file.name);
        setInputForm("file", file);
      } catch (err) {
        toast.error("Error processing file: " + err.message);
        setInputForm("file", null);
        setInputForm("filename", "");
      } finally {
        setLoading(false);
      }
    };

    return (
      <Layout>
        <div className="bg-white w-auto p-0.5 sm:p-1 my-2 sm:my-3 rounded-md border border-[#7D7D7D]">
          <SwitchButton
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
        </div>
        <p className="text-base text-neutral-500 font-normal mb-4 block sm:hidden">
          For the better experience with audio recordings, download our Navix
          Scribe App. Enjoy enhanced features and seamless recording on the go.
          Download now!
        </p>
        <div className="w-full mb-1">
          <div className="flex gap-2 sm:justify-center items-start sm:flex-col justify-start sm:items-center">
            <label
              htmlFor="download-app"
              //disabled for now
              className="disabled sm:hidden w-full sm:w-80 cursor-pointer inline-flex justify-center gap-3 items-center py-1 rounded-md text-white text-center text-sm transition hover:from-gray-700 hover:to-gray-800"
              style={{ backgroundColor: "#4336AB" }}
            >
              Navix Scribe App
              <DownloadIcon className="w-6 h-6" />
            </label>
            <label
              htmlFor="text-upload"
              className="w-full sm:w-80 cursor-pointer inline-flex justify-center gap-3 items-center py-1 rounded-md text-white text-center text-sm sm:text-base transition hover:from-gray-700 hover:to-gray-800"
              style={{ backgroundColor: "#1E2E39" }}
            >
              <UploadIcon className="w-6 h-6" />
              Choose a file
            </label>
          </div>
          <input
            type="file"
            name="file"
            id="text-upload"
            className="hidden"
            accept={inputAccept}
            onChange={(e) => handleFileChangeEvent(e)}
          />
          <span className="text-sm block text-center text-gray-500">
            .txt, .pdf, .mp3 and .docx are only supported
          </span>
          <AudioComponent
            activeButton={activeButton}
            setAudioUrl={setAudioUrl}
            setInputForm={navixScribeStore.setInputForm}
            setIsRecording={setIsRecording}
            isRecording={isRecording}
            audioUrl={audioUrl}
            setAudioDuration={setAudioDuration}
            audioFile={navixScribeStore.inputForm.file}
            ref={audioComponentRef}
          />
          {navixScribeStore.inputForm.file && (
            <input
              className="w-full border rounded-md border-gray-400 p-1 mb-2 text-md"
              type="text"
              name="filename"
              disabled
              id="filename"
              value={navixScribeStore.inputForm.filename}
            />
          )}
          <TextArea
            activeButton={activeButton}
            handleOnChangeEvent={handleOnChangeEvent}
            inputText={navixScribeStore.inputForm.inputText}
          />
        </div>
        {isRecording && (
          <div className="text-center text-gray-700 font-semibold">
            Recording Time: {formatElapsedTime(navixScribeStore.elapsedTime)}
          </div>
        )}
        <GenerateButton
          onClick={handleSubmitEvent}
          disabled={isGenerateButtonDisabled()}
        >
          <Loader active={loading} className="w-6 h-6 mr-1" />
          {activeButton === "text" ? "Save" : "Transcribe"}
        </GenerateButton>
        <Modal
          open={showUpgradeModal}
          onClose={() => setShowUpgradeModal(false)}
        >
          <div className="text-center p-6 bg-white rounded-lg shadow-lg max-w-lg mx-auto">
            <h3 className="text-xl font-semibold text-left text-slate-800 mb-4">
              {modalMessage}
            </h3>
            <p className="text-left text-md text-zinc-700 mb-6">
              Unlock the full power of Navix AI by upgrading to the Pro version.
              Enjoy unlimited access to Navix Scribe, plus a host of other
              premium benefits designed to enhance your productivity and
              efficiency.
            </p>
            <ul className="text-left text-md text-zinc-700 mb-6 space-y-3">
              <li>
                <strong className="text-slate-800">750 x Credits:</strong> More
                actions to get your work done.
              </li>
              <li>
                <strong className="text-slate-800">45,000 x Words:</strong>{" "}
                Generate extensive content effortlessly.
              </li>
              <li>
                <strong className="text-slate-800">180,000 x Letters:</strong>{" "}
                Write detailed and comprehensive documents.
              </li>
              <li>
                <strong className="text-slate-800">Navix Scribe:</strong> Your
                go-to tool for AI-powered writing and documentation.
              </li>
              <li>
                <strong className="text-slate-800">Access to All Tools:</strong>{" "}
                Utilize the full suite of Navix AI tools to maximize your
                workflow.
              </li>
              <li>
                <strong className="text-slate-800">
                  Early Access to Beta Tools:
                </strong>{" "}
                Be the first to try new features and provide feedback.
              </li>
            </ul>
            <div className="flex justify-between items-center">
              <button
                className="bg-indigo-600 text-white font-semibold px-4 py-2 rounded-md hover:bg-indigo-700 transition"
                onClick={handleUpgradeClick}
              >
                Upgrade Now
              </button>
              <button
                className="text-indigo-600 font-semibold px-4 py-2 rounded-md hover:text-indigo-700 transition"
                onClick={() => setShowUpgradeModal(false)}
              >
                Not Now
              </button>
            </div>
          </div>
        </Modal>
      </Layout>
    );
  })
);

function Layout({ children }) {
  return (
    <div className="flex flex-col justify-center items-start sm:items-center">
      {children}
    </div>
  );
}

function SwitchButton({ activeButton, setActiveButton }) {
  return (
    <>
      <button
        className={`px-5 sm:px-7 sm:py-0 navix-scribe-accordion-button${
          activeButton === "audio" ? "--active" : ""
        }`}
        onClick={() => setActiveButton("audio")}
      >
        Audio
      </button>
      <button
        className={`px-5 sm:px-7 sm:py-0 navix-scribe-accordion-button${
          activeButton === "text" ? "--active" : ""
        }`}
        onClick={() => setActiveButton("text")}
      >
        Text
      </button>
    </>
  );
}

function AudioComponent({
  activeButton,
  setAudioUrl,
  setInputForm,
  setIsRecording,
  isRecording,
  audioUrl,
  setAudioDuration,
  audioFile,
}) {
  if (activeButton === "audio") {
    return (
      <div className="block text-center pb-2">
        <WaveSurferRecorder
          onAudioUrl={(e) => {
            setAudioUrl(e);
          }}
          onBlob={(e) => {
            setInputForm("file", e);
            setInputForm("filename", "");
          }}
          recording={(e) => setIsRecording(e)}
          isRecording={isRecording}
        />
        {audioUrl && (
          <EntryAudio
            audioUrl={audioUrl}
            audioFile={audioFile}
            getAudioDuration={(val) => setAudioDuration(val)}
          />
        )}
      </div>
    );
  }
}

function TextArea({ activeButton, handleOnChangeEvent, inputText }) {
  if (activeButton === "text") {
    return (
      <div className="mr-auto w-full mb-2">
        <label
          htmlFor="text-input"
          className="cursor-pointer pt-1 rounded-md text-black block"
        >
          Input text in the box below
        </label>
        <textarea
          className="w-full border rounded-md border-gray-600 p-1 text-md"
          type="text"
          name="inputText"
          id="text-input"
          onChange={handleOnChangeEvent}
          rows={10}
          value={inputText}
        />
      </div>
    );
  }
}

const GenerateButton = styled.button`
  border-radius: 5.89px;
  background: rgba(139, 35, 239, 1);
  padding: 0.5rem 2rem;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background: rgba(139, 35, 239, 0.9);
  }

  &:active {
    background: rgb(92, 40, 160);
  }

  &:disabled {
    cursor: not-allowed;
    background: #451e78;
  }

  @media (max-width: 640px) {
    padding: 0.25rem 0.75rem;
    font-size: 14px;
    font-weight: 300;
  }
`;

export default Inputs;
