import { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { observable, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import {
  DocumentDownloadIcon,
  XIcon,
  RefreshIcon,
  DotsVerticalIcon,
  SearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import FileDownload from "js-file-download";
import toast from "react-hot-toast";
import Header from "../Components/Header";
import Body from "../Components/Body";
import Pagination from "../Components/Pagination";

@inject("store")
@observer
class AdminTool extends Component {
  @observable tool = {};
  @observable perPage = null;
  @observable currentPage = null;
  @observable fields = [];
  @observable total = 0;
  @observable users = [];
  @observable filter = {};
  @observable selectedPlan = null;
  @observable search = null;
  @observable sortBy = null;
  @observable orderBy = null;
  @observable showDropdownMenu = false;
  @observable dropdownMenuId = null;
  @observable isUpdating = false;
  @observable userId = null;
  @observable credits = 0;
  @observable plan = null;
  @observable plans = ["VIP", "Hub", "Employee"];
  @observable userPlans = ["Trial", "Entry", "Pro"];
  @observable showAddCreditsModal = false;
  @observable showModifyPlanModal = false;
  @observable showChangePasswordModal = false;
  @observable newPassword = "";
  @observable errorMessage = "";

  constructor(props) {
    super(props);

    makeObservable(this);

    this.tool = this.props.store.getAdminToolByUrl(
      this.props.location.pathname
    );

    if (!this.tool) {
      window.location.href = "/";
    }

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    this.selectedPlan = searchParams.get("plan");
    this.search = searchParams.get("search");

    this.sortBy = searchParams.get("sort_by");
    this.orderBy = searchParams.get("order_by");
    this.currentPage = searchParams.get("page");

    this.filter = {
      plan: this.selectedPlan,
      search: this.search,
    };

    this.getUsers();
  }

  getUsers = async () => {
    try {
      const response = await this.props.store.api.get(this.tool.api, {
        params: {
          filter: JSON.stringify(this.filter),
          sort_by: this.sortBy,
          order_by: this.orderBy,
          page: this.currentPage,
        },
      });

      const data = response.data;
      const result = data.result;

      if (data.success) {
        this.fields = result.fields;
        this.perPage = result.per_page;
        this.total = result.total;
        this.users = result.users;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  exportCsv = async () => {
    try {
      const response = await this.props.store.api.get(this.tool.api, {
        params: {
          type: "export",
          file: "csv",
        },
      });

      const data = response.data;

      if (data) {
        FileDownload(data, "users.csv");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  handleSearch = (event) => {
    event.preventDefault();
    const url = this.generateFilterUrl("search", this.search);
    this.props.history.push(url);
    this.filter.search = this.search;
    this.getUsers();
  };

  handlePlanChange = (plan) => {
    const url = this.generateFilterUrl("plan", plan);
    this.props.history.push(url);
    this.filter.plan = plan;
    this.selectedPlan = plan;
    this.showDropdownMenu = false;
    this.getUsers();
  };

  generateFilterUrl = (filter = null, value = null) => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    if (filter && value) {
      searchParams.set(filter, value);
    }

    return url.pathname + "?" + searchParams.toString();
  };

  setShowAddCreditsModal = (value) => {
    this.showAddCreditsModal = value;
  };

  setShowModifyPlanModal = (value) => {
    this.showModifyPlanModal = value;
  };

  setShowChangePasswordModal = (value) => {
    this.showChangePasswordModal = value;
  };

  setErrorMessage = (message) => {
    this.errorMessage = message;
  };

  handleUpdateCredits = async (userId) => {
    this.isUpdating = true;

    try {
      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}`,
        {
          credits: this.credits,
        }
      );

      const data = response.data;

      if (data.success) {
        this.users = this.users.map((user) => {
          if (user._id === userId) {
            user.credits = data.result.user.credits;
          }

          return user;
        });

        toast.success("Successfully updated credits.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.isUpdating = false;
      this.userId = null;
      this.credits = 0;
      this.setShowAddCreditsModal(false);
    }
  };

  handleUpdatePlan = async (userId) => {
    this.isUpdating = true;

    try {
      if (!this.plans.includes(this.plan)) {
        this.plan = "trial";
      }

      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}`,
        {
          plan: this.plan,
        }
      );

      const data = response.data;

      if (data.success) {
        const { plan, status, current_period_end } = data.result.user;

        this.users = this.users.map((user) => {
          if (user._id === userId) {
            user.plan = plan;
            user.status = status;
            user.current_period_end = current_period_end;
          }

          return user;
        });

        toast.success("Successfully updated plan.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.isUpdating = false;
      this.userId = null;
      this.plan = null;
      this.setShowModifyPlanModal(false);
    }
  };

  handleChangePassword = async (userId) => {
    if (this.newPassword.length < 6) {
      this.setErrorMessage("Password must be at least 6 characters long");
      return;
    }

    this.isUpdating = true;
    this.setErrorMessage("");

    try {
      const response = await this.props.store.api.put(
        `${this.tool.api}/${userId}/change-password`,
        {
          password: this.newPassword,
        }
      );

      const data = response.data;

      if (data.success) {
        toast.success("Successfully changed password.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.isUpdating = false;
      this.userId = null;
      this.newPassword = "";
      this.setShowChangePasswordModal(false);
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} - NavixAI`}</title>
        </Helmet>

        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
        />

        <Body className="p-8">
          <button
            type="button"
            className="px-5 py-2.5 mb-4 text-sm font-medium text-white inline-flex items-center bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-400 rounded-lg text-center"
            onClick={this.exportCsv}
          >
            <DocumentDownloadIcon className="mr-2 h-4 w-4" /> Export
          </button>

          <form
            method="GET"
            className="flex mb-4 w-1/2"
            onSubmit={this.handleSearch}
          >
            <div className="relative w-36">
              <button
                type="button"
                className="px-5 py-2.5 w-full text-sm font-medium text-white flex items-center bg-gray-500 hover:bg-gray-600 rounded-l-lg text-center"
                onClick={() => {
                  this.showDropdownMenu = !this.showDropdownMenu;
                  this.dropdownMenuId = "filter";
                }}
              >
                {!this.selectedPlan || this.selectedPlan === "all"
                  ? "All plans"
                  : this.selectedPlan}
                <ChevronDownIcon className="ml-2 w-4 h-4" />
              </button>

              {this.showDropdownMenu && this.dropdownMenuId === "filter" && (
                <div>
                  <div
                    className="fixed inset-0 z-10"
                    onClick={() => {
                      this.showDropdownMenu = false;
                    }}
                  ></div>

                  <div className="absolute left-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                    <ul className="py-2 text-sm text-gray-700">
                      {["All plans"]
                        .concat(this.userPlans)
                        .concat(this.plans)
                        .map((plan, index) => (
                          <li key={index}>
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.handlePlanChange(
                                  plan === "All plans" ? "all" : plan
                                );
                              }}
                              className="block px-4 py-2 hover:bg-gray-100"
                            >
                              {plan}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            <div className="flex w-full">
              <input
                type="text"
                name="search"
                className="block w-full border border-gray-300 bg-gray-50 px-5 py-2.5 text-sm text-gray-900"
                placeholder="Search by name or email"
                value={this.search ?? ""}
                onChange={(e) => {
                  this.search = e.target.value;
                }}
              />

              <button
                type="submit"
                className="px-5 py-2.5 text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 rounded-r-lg text-center"
              >
                <SearchIcon className="w-4 h-4" />
              </button>
            </div>
          </form>

          <div className="relative">
            <table className="w-full bg-white text-sm text-left">
              <thead className="text-xs">
                <tr className="border-b text-center">
                  <th className="px-4 py-2">
                    <SortableLink sortBy={"name"}>NAME</SortableLink>
                  </th>
                  <th className="px-4 py-2">EMAIL</th>
                  <th className="px-4 py-2 w-16">GATEWAY</th>
                  <th className="px-4 py-2 w-16">PLAN</th>
                  <th className="px-4 py-2 w-16">STATUS</th>
                  <th className="px-4 py-2 w-16">IS SUBSCRIPTION<br/>VALID?</th>
                  <th className="px-4 py-2 w-16">
                    <SortableLink sortBy={"created"}>SIGNED UP</SortableLink>
                  </th>
                  <th className="px-4 py-2 w-16">
                    <SortableLink sortBy={"trial_end"}>
                      TRIAL<br/>EXPIRATION
                    </SortableLink>
                  </th>
                  <th className="px-4 py-2 w-16">
                    <SortableLink sortBy={"current_period_end"}>
                      SUBSCRIPTION<br/>EXPIRATION
                    </SortableLink>
                  </th>
                  <th className="px-4 py-2 w-16">AUTO-RENEWAL</th>
                  <th className="px-4 py-2 w-32">TOKENS USED</th>
                  <th className="px-4 py-2 w-32">TOKENS BALANCE</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {this.users.map((user, index) => (
                  <tr
                    key={user._id}
                    className={`hover:bg-gray-100 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                  >
                    <td className="px-4 py-2">{user.name}</td>
                    <td className="px-4 py-2">{user.email}</td>
                    <td className="px-4 py-2">{user.gateway.charAt(0).toUpperCase() + user.gateway.slice(1)}</td>
                    <td className="px-4 py-2">{user.plan}</td>
                    <td className="px-4 py-2">{user.status}</td>
                    <td className="px-4 py-2">{user.isSubscriptionValid ? "Yes" : "No"}</td>
                    <td className="px-4 py-2 text-center">{user.created}</td>
                    <td className="px-4 py-2 text-center">
                      {user.trial_end !== "Invalid date"
                        ? user.trial_end
                        : "N/A"}
                    </td>
                    <td className="px-4 py-2 text-center">
                      {user.current_period_end}
                    </td>
                    <td className="px-4 py-2 text-center">
                      {user.auto_renewal}
                    </td>
                    <td className="px-4 py-2 text-right">{user.creditsUsed}</td>
                    <td className="px-4 py-2 text-right">{user.credits}</td>
                    <td className="p-2">
                      <div className="relative">
                        <button
                          type="button"
                          className="inline-flex items-center text-sm font-medium text-center text-gray-900 rounded-lg hover:bg-gray-100"
                          onClick={() => {
                            this.showDropdownMenu = !this.showDropdownMenu;

                            this.dropdownMenuId = user._id;
                          }}
                        >
                          <DotsVerticalIcon className="w-4 h-4" />
                        </button>

                        {this.showDropdownMenu &&
                          this.dropdownMenuId === user._id && (
                            <div>
                              <div
                                className="fixed inset-0 z-10"
                                onClick={() => {
                                  this.showDropdownMenu = false;
                                }}
                              ></div>

                              <div className="absolute right-0 z-20 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                                <ul className="py-2 text-sm text-gray-700">
                                  <li>
                                    <a
                                      href="#"
                                      className="block px-4 py-2 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.showDropdownMenu = false;
                                        this.setShowAddCreditsModal(true);
                                        this.userId = user._id;
                                        this.credits = user.credits;
                                      }}
                                    >
                                      Edit Credits
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="block px-4 py-2 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.showDropdownMenu = false;
                                        this.setShowModifyPlanModal(true);
                                        this.userId = user._id;
                                        this.plan = user.plan;
                                      }}
                                    >
                                      Modify Plan
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="#"
                                      className="block px-4 py-2 hover:bg-gray-100"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        this.showDropdownMenu = false;
                                        this.setShowChangePasswordModal(true);
                                        this.userId = user._id;
                                      }}
                                    >
                                      Change Password
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            items={this.users}
            total={this.total}
            perPage={this.perPage}
            currentPage={this.currentPage}
          />
        </Body>

        {this.showAddCreditsModal && (
          <Modal
            title={"Edit Credits"}
            value={this.credits}
            disableButtons={this.isUpdating}
            handleValue={(e) => {
              this.credits = e.target.value;
            }}
            handleSave={() => {
              this.handleUpdateCredits(this.userId);
            }}
            handleCancel={() => {
              this.setShowAddCreditsModal(false);
              this.userId = null;
              this.credits = 0;
            }}
          />
        )}

        {this.showModifyPlanModal && (
          <Modal
            title={"Modify Plan"}
            options={this.plans}
            defaultValue={this.plans.includes(this.plan) ? this.plan : "trial"}
            value={this.plan}
            disableButtons={this.isUpdating}
            handleValue={(e) => {
              this.plan = e.target.value;
            }}
            handleSave={() => {
              this.handleUpdatePlan(this.userId);
            }}
            handleCancel={() => {
              this.setShowModifyPlanModal(false);
              this.userId = null;
              this.plan = null;
            }}
          />
        )}

        {this.showChangePasswordModal && (
          <Modal
            title={"Change Password"}
            value={this.newPassword}
            disableButtons={this.isUpdating}
            handleValue={(e) => {
              this.newPassword = e.target.value;
            }}
            handleSave={() => {
              this.handleChangePassword(this.userId);
            }}
            handleCancel={() => {
              this.setShowChangePasswordModal(false);
              this.userId = null;
              this.newPassword = "";
            }}
            errorMessage={this.errorMessage}
          />
        )}
      </>
    );
  }
}

const Modal = ({
  title,
  options,
  defaultValue,
  value,
  disableButtons,
  handleValue,
  handleSave,
  handleCancel,
  errorMessage,
}) => {
  return (
    <div className="flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-96 my-6 mx-auto">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 pb-0 border-gray-300 rounded-t">
            <h3 className="mb-5 text-lg font-normal">{title}</h3>

            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              disabled={disableButtons}
              onClick={handleCancel}
            >
              <XIcon className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6 pt-0 text-center">
            <div className="mb-4">
              {title === "Edit Credits" && (
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2"
                  value={value}
                  onChange={handleValue}
                  autoFocus={true}
                  min={1}
                />
              )}

              {title === "Modify Plan" && (
                <select
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2"
                  onChange={handleValue}
                  defaultValue={defaultValue}
                >
                  <option value="trial">Choose a plan</option>
                  {options.map((text, currentValue) => {
                    return (
                      <option key={currentValue} value={text}>
                        {text}
                      </option>
                    );
                  })}
                </select>
              )}

              {title === "Change Password" && (
                <>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2"
                    value={value}
                    onChange={handleValue}
                    autoFocus={true}
                  />
                  {errorMessage && (
                    <div className="text-red-500 text-sm mt-2">
                      {errorMessage}
                    </div>
                  )}
                </>
              )}
            </div>

            <button
              type="button"
              className="hover:text-white border border-gray-200 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              disabled={disableButtons}
              onClick={handleSave}
            >
              {disableButtons && (
                <RefreshIcon className="h-3 w-3 animate-spin" />
              )}
              Change Password
            </button>

            <button
              type="button"
              className="bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
              disabled={disableButtons}
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SortableLink = ({ children, sortBy }) => {
  const [url, setUrl] = useState(null);
  const [currentSortBy, setCurrentSortBy] = useState(null);
  const [currentOrderBy, setCurrentOrderBy] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const sort_by = searchParams.get("sort_by");
    const order_by = searchParams.get("order_by");
    const orderBy = sortBy !== sort_by ? "asc" : "desc";

    setCurrentSortBy(sort_by);
    setCurrentOrderBy(order_by);

    searchParams.set("sort_by", sortBy);
    searchParams.set("order_by", orderBy);

    setUrl(url.pathname + "?" + searchParams.toString());
  }, [sortBy]);

  return (
    <a href={url} className="flex items-center justify-center">
      {children}

      <span className="ml-2">
        {currentSortBy === sortBy ? (
          <>
            {currentOrderBy === "asc" && <ChevronUpIcon className="w-3 h-3" />}

            {currentOrderBy === "desc" && (
              <ChevronDownIcon className="w-3 h-3" />
            )}
          </>
        ) : (
          <>
            <ChevronUpIcon className="w-3 h-3 -mb-1" />
            <ChevronDownIcon className="w-3 h-3 -mt-1" />
          </>
        )}
      </span>
    </a>
  );
};

export default withRouter(AdminTool);
