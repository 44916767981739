import { ThemeProvider } from "styled-components";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { Provider } from "mobx-react";
import { observer } from "mobx-react";
import AppStore from "./store";
import colors from "tailwindcss/colors";
import notifySound from "./media/notify.mp3";
import useIsWebView from "./Hooks/useIsWebView";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Header from "./Header";
import Search from "./Search";
import Pricing from "./Pricing";
import Dashboard from "./Dashboard";
import Tool from "./Core/Tool";
import Chat from "./Core/Chat";
import AdminTool from "./Core/AdminTool";
import Login from "./Login/Login";
import Profile from "./Profile/";
import LoginSuccess from "./Login/Success";
import "./styles/index.scss";
import "./styles/App.scss";
import toast, { Toaster } from "react-hot-toast";
import ForgotPassword from "./Login/ForgotPassword";
import NavixScribe from "./Core/NavixScribe";
import { navixScribeStore } from "./Core/NavixScribe/NavixScribeStore";
import FloatingBanner from "./Components/FloatingBanner";
import NavixScribeV2 from "./Core/NavixScribeV2";
import { navixScribeV2Store } from "./Core/NavixScribeV2/NavixScribeV2Store";

if (!window.store) {
  window.store = new AppStore();
}

const App = observer(() => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Connect to the socket
    connectToSocket();

    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!!window.store.profile.status) {
      if (!socket?.connected) {
        connectToSocket();
      }
    } else {
      socket?.disconnect();
    }
  }, [window.store.profile.status]);

  const connectToSocket = () => {
    if (!!window.store.profile.status) {
      const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
      const newSocket = io(WEBSOCKET_URL, {
        auth: {
          token: localStorage.getItem("token"),
        },
      });

      newSocket.on(
        "assembly-transcription-webhook-success",
        ({ message, history }) => {
          new Audio(notifySound).play();
          const { inputActiveRecord, setInputActiveRecord, setTriggerSection } =
            navixScribeStore;

          console.log("WebSocket Success - Incoming History:", history);

          if (inputActiveRecord?._id === history._id) {
            setInputActiveRecord(history);
            setTriggerSection("Input");
            toast.success((t) => <span>{message},&nbsp; !</span>, {
              duration: 5000,
            });
          }
        }
      );

      newSocket.on("assembly-transcription-webhook-error", (error) => {
        const message =
          typeof error === "string"
            ? error
            : error.message || "An unknown error occurred";
        const { inputActiveRecord, setInputActiveRecord, setTriggerSection } =
          navixScribeStore;

        console.log("WebSocket Error - Incoming Error:", error);

        if (
          inputActiveRecord &&
          inputActiveRecord.audioTranscriber.transcript_id ===
            error.transcript_id
        ) {
          setInputActiveRecord({
            ...inputActiveRecord,
            audioTranscriber: {
              ...inputActiveRecord.audioTranscriber,
              status: "error",
            },
          });
          setTriggerSection("Input");
          toast.error(message, { duration: 4000 });
        }
      });

      setSocket(newSocket);
    }
  };

  return (
    <ThemeProvider theme={colors}>
      <Provider
        store={window.store}
        navixScribeStore={navixScribeStore}
        navixScribeV2Store={navixScribeV2Store}
      >
        <Router>
          <Toaster position="top-right" />
          <ConditionalFloatingBanner />
          {window.store.redirect ? (
            <Redirect to={window.store.redirect} />
          ) : null}

          {window.store.isLoggedIn ? (
            <>
              {" "}
              {/* Logged in */}
              <Switch>
                <Route path="/writing/document">
                  <div />
                </Route>
                <Route component={Header} />
              </Switch>
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/search" exact component={Search} />
                <Route path="/ai/advisors" component={Chat} />
                <Route
                  path="/ai/audio/transcriber-new"
                  render={(props) => (
                    <NavixScribe
                      navixScribeStore={navixScribeStore}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/ai/audio/navixscribe-v2"
                  render={(props) => (
                    <NavixScribeV2
                      navixScribeV2Store={navixScribeV2Store}
                      {...props}
                    />
                  )}
                />
                <Route path="/ai/" component={Tool} />
                <Route path="/my-profile" component={Profile} />
                <Route path="/signup/failed" component={Profile} />
                <Route path="/login/success" component={LoginSuccess} />

                {window.store.profile.accountType === "admin" ? (
                  <>
                    {" "}
                    {/* Admin only */}
                    <Route path="/admin" component={AdminTool} />
                  </>
                ) : (
                  <Redirect to="/" />
                )}
              </Switch>
            </>
          ) : (
            <>
              {" "}
              {/* Not Logged in */}
              <Switch>
                <Route path="/login/success" component={LoginSuccess} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/" exact>
                  <Redirect to="/login" />
                </Route>
                <Route path="/" component={Login} />
              </Switch>
            </>
          )}
        </Router>
      </Provider>
    </ThemeProvider>
  );
});

const ConditionalFloatingBanner = () => {
  const location = useLocation();
  const isWebView = useIsWebView();
  return location.pathname !== "/login" &&
    location.pathname !== "/ai/audio/navixscribe-v2" &&
    !isWebView ? (
    <FloatingBanner />
  ) : null;
};

export default App;
